<template>
  <ERow no-gutters class="details">
    <ECol cols="12">
      <ERow class="ma-0">
        <ECol class="py-1">
          <SummaryElement
            label="Serial Number"
            :value="router.serialNumber"
            read-only
          />
          <SummaryElement
            label="Router Type"
            :value="router.routerType"
            read-only
          />
          <SummaryElement
            label="Router HTTP Port"
            :value="router.routerHttpPort"
            read-only
          />
          <SummaryElement
            label="Router User ID"
            :value="router.routerUserId"
            read-only
            is-copyable
          />
          <SummaryElement
            label="Router Password"
            :value="router.routerPassword"
            read-only
            is-password
            is-copyable
          />
          <SummaryElement
            label="VPN Server"
            :value="router.vpnServer"
            read-only
          />
          <SummaryElement
            label="VPN User ID"
            :value="router.vpnUserId"
            read-only
            is-copyable
          />
          <SummaryElement
            label="VPN Password"
            :value="router.vpnPassword"
            read-only
            is-password
            is-copyable
          />
          <SummaryElement
            label="Power Type"
            :value="router.powerType"
            read-only
          />
        </ECol>

        <v-divider :vertical="$vuetify.breakpoint.smAndUp"></v-divider>

        <ECol class="py-1">
          <SummaryElement read-only label="Cameras">
            <CamerasColumn
              :item="router"
              @open-camera-dialog="
                cameraDialogStore.openDialog({ camera: $event })
              "
            />
          </SummaryElement>

          <SummaryElement read-only label="Projects">
            <div v-if="router.projects && router.projects.length > 0">
              <span
                v-for="(project, index) in router.projects"
                :key="`${project.id}${index}`"
                class="cursor-pointer primary--text"
                @click="projectStore.openDialog(project.exid)"
              >
                {{
                  index !== router.projects.length - 1
                    ? `${project.name},`
                    : project.name
                }}
              </span>
            </div>
            <span v-else>-</span>
          </SummaryElement>

          <SummaryElement read-only label="Sims">
            <SimsColumn :item="router" />
          </SummaryElement>

          <SummaryElement read-only label="Bill Payers">
            <EReadMore :content="billPayers" :max-length="3" />
          </SummaryElement>
        </ECol>
      </ERow>
      <v-divider></v-divider>

      <ERow class="ma-0">
        <v-tabs v-model="activeTab" class="router-summary__tabs">
          <v-tab v-if="router.id" :key="0">Logs</v-tab>
          <v-tab v-if="router.sims" :key="1">SMS History</v-tab>
          <v-tab v-if="showBatteryReadingsTab" :key="2">Battery Readings</v-tab>

          <v-tab-item v-if="router.id" :key="0">
            <RouterAuditLogs :router-id="router.id" />
          </v-tab-item>
          <v-tab-item v-if="router.sims && router.sims.length > 0" :key="1">
            <div class="pt-2">
              <SmsHistory
                :sim-number="router.sims[0].number"
                :read-only="readOnly"
              />
            </div>
          </v-tab-item>
          <v-tab-item v-if="showBatteryReadingsTab" :key="2">
            <BatteryReadings :id="router.id" />
          </v-tab-item>
        </v-tabs>
      </ERow>
    </ECol>
  </ERow>
</template>

<script lang="ts">
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement"
import CamerasColumn from "@/components/CamerasColumn"
import SimsColumn from "@/components/SimsColumn"
import SmsHistory from "@/components/cameraTabs/SmsHistory"
import BatteryReadings from "@/components/batteries/BatteryReadings"
import Vue, { PropType } from "vue"
import { Router, PowerType } from "@evercam/shared/types/router"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default Vue.extend({
  name: "RouterSummaryGeneral",
  components: {
    SummaryElement,
    CamerasColumn,
    SimsColumn,
    SmsHistory,
    BatteryReadings,
    RouterAuditLogs: () => import("@/components/auditLogs/AuditLogsTable"),
  },
  props: {
    router: {
      type: Object as PropType<Router>,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 0,
    }
  },
  computed: {
    ...mapStores(useProjectStore, useCameraDialogStore),
    showBatteryReadingsTab(): boolean {
      return [PowerType.Solar, PowerType.Battery].includes(
        this.router.powerType
      )
    },
    billPayers() {
      return this.router?.billPayers?.map((item) => item.name)
    },
  },
})
</script>

<style>
.router-summary__tabs .v-window {
  overflow: visible !important;
}
</style>
