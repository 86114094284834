<template>
  <v-dialog
    v-model="routerStore.dialog"
    eager
    fullscreen
    hide-overlay
    scrollable
    persistent
    transition="fade-transition"
    class="router-summary"
    :content-class="`router-summary ${routerScreenSize}`"
    no-click-animation
    @keydown.esc="$emit('close')"
  >
    <v-card>
      <v-card-title class="pa-0 pl-2 router-summary__header">
        <RouterSummaryHeader
          :router="routerStore.selectedRouter"
          @close="$emit('close')"
        />
      </v-card-title>
      <v-divider />
      <v-card-text class="px-0 pb-0 overflow">
        <RouterSummaryGeneral
          :router="routerStore.selectedRouter"
          :read-only="readOnly"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import RouterSummaryHeader from "@/components/routers/RouterSummaryHeader"
import RouterSummaryGeneral from "@/components/routers/RouterSummaryGeneral"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useRouterStore } from "@/stores/router"

export default {
  name: "RouterSummaryDialog",
  components: {
    RouterSummaryHeader,
    RouterSummaryGeneral,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useCameraDialogStore, useRouterStore),
    routerScreenSize() {
      if (this.cameraDialogStore.hideSidebar) {
        return "router-summary--fullscreen-no-side"
      }

      if (this.cameraDialogStore.tabFullScreen) {
        return "router-summary--fullscreen"
      }

      return ""
    },
  },
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";
@import "assets/vars";

.router-summary {
  overflow: hidden;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    max-width: calc(100vw - #{$sidebar-width});
    margin-left: $sidebar-width !important;
  }

  &__header {
    box-shadow: 0px 5px 12px -13px #0000007a;
    z-index: 1;
  }

  &__content {
    overflow: hidden;
  }

  &--fullscreen {
    position: relative;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: calc(100vw - #{$sidebar-width--sm});
      margin-left: $sidebar-width--sm !important;
    }
  }

  &--fullscreen-no-side {
    position: relative;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: 100%;
      margin-left: 0 !important;
    }
  }
}
</style>
